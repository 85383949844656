import 'globals.css'
import App from 'next/app'
import Head from 'next/head'
import { withRouter } from 'next/router'
import Script from 'next/script'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Layout from 'components/Layout'
import { PaymentContextProvider } from 'context/PaymentContext'
import DeviceDetector from 'device-detector-js'
import DetectionHelper from 'utils/DeviceDetection'
import theme from 'theme'
import config from 'config'
import OGImage from 'assets/og-image.png'
import withRedux from 'next-redux-wrapper'
import createStore from 'store/createStore'
import { Provider as ReduxProvider } from 'react-redux'
import { GOOGLE_PAY_LOAD } from 'libs/useGooglePay'

const { GTM_ID } = process.env

class InfinitePaymentLinkApp extends App {
  static async getInitialProps (_) {
    return {}
  }
  constructor(props) {
    super(props)

    const deviceDetector = new DeviceDetector()
    const userAgent =
      typeof window !== 'undefined'
        ? window.navigator.userAgent
        : 'Mozilla/5.0 AppleWebKit/537.36 (KHTML, like Gecko; compatible; Googlebot/2.1; +http://www.google.com/bot.html) Chrome/W.X.Y.Z Safari/537.36'
    const userHardware = deviceDetector.parse(userAgent)
    const detector = DetectionHelper(userHardware)
    const { cyberSourceOrgId, cyberSourceMerchantId } = config
    const cyberSourceUrl = `https://authorizer-data.infinitepay.io/fp/tags.js?org_id=${cyberSourceOrgId}&session_id=${cyberSourceMerchantId}${config.createSessionID()}`

    this.state = {
      mounted: false,
      userHardware,
      cyberSourceUrl,
      sessionId: config.sessionId,
      deviceDetection: detector,
    }
  }

  componentDidMount() {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
    this.setState({
      mounted: true,
    })
  }

  #onAuthChanged = (user) => {
    if (user) {
      this.setState({
        signedUser: user ?? undefined,
      })
    }
  }

  render() {
    const { Component, pageProps, router, store } = this.props
    const { cyberSourceUrl, sessionId } = this.state
    const params = router?.query || { handle: '', amount: null }
    const OGAmount = Number(params.amount).toLocaleString('pt-br', { minimumFractionDigits: 2 })

    let search =
      router.asPath.lastIndexOf('?') > -1
        ? router.asPath.substring(router.asPath.lastIndexOf('?'))
        : ''

    return (
      <>
        <Head>
          <title>InfinitePay : Link de Pagamento</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          <meta name="theme-color" content={theme.palette.background.default} />
          <meta
            name="apple-itunes-app"
            content={`app-id=1506929912, app-argument=https://pay.infinitepay.io/${
              params.handle && params.handle + '/' + (params.amount || '')
            }`}
          />
          <meta
            content="InfinitePay | Você recebeu um link de pagamento"
            name="twitter:title"
          />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="@infinitepay" name="twitter:site" />
          <meta content="@infinitepay" name="twitter:creator" />
          <meta content="InfinitePay" name="twitter:text:title" />
          <meta content={config.paymentLinkUI + OGImage.src} property="twitter:image" />
          <meta content="InfinitePay | Você recebeu um link de pagamento" property="og:title" />
          <meta content="website" property="og:type" />
          <meta content={config.paymentLinkUI + OGImage.src} property="og:image" />
          <meta content="InfinitePay" property="og:site_name" />
          <meta
            content="Clique no link abaixo para fazer o pagamento agora mesmo!"
            property="og:description"
          />
          <meta
            content="Clique no link abaixo para fazer o pagamento agora mesmo!"
            name="twitter:description"
          />
          <link rel="shortcut icon" href="/static/favicon.png" />
          <link rel="icon" type="image/png" href="/static/favicon.png" />
          {this.state.mounted && <script type="text/javascript" src={cyberSourceUrl} async />}
        </Head>
        <Script id="clarity_tag" strategy="beforeInteractive">
          {`
              (function(c,l,a,r,i,t,y){
                c[a] = c[a] || function () { (c[a].q = c[a].q || 
                  []).push(arguments) };
                  t=l.createElement(r);
                  t.async=1;
                  t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];
                  y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "eonkxemd21");
            `}
        </Script>
        <Script async src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`} />
        <Script id="gtag_tag" strategy="beforeInteractive">
          {`
                  window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${GTM_ID}', {page_path: window.location.pathname});
                `}
        </Script>
        {pageProps?.product_allowed && pageProps?.product_allowed['wallet-pay'] && <Script async src={`https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js`} /> }
        {pageProps?.product_allowed && pageProps?.product_allowed['google-pay'] && <Script async src={`https://pay.google.com/gp/p/js/pay.js`} onLoad={GOOGLE_PAY_LOAD} /> }
        <ReduxProvider store={store}>
          <PaymentContextProvider
            values={{
              userData: pageProps?.userData,
              invoiceData: pageProps?.invoiceData,
              accept: pageProps?.accept,
              product_allowed: pageProps?.product_allowed,
              params,
              search,
              sessionId,
              ...this.state,
            }}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Layout>
                <Component router={router} {...pageProps} />
              </Layout>
              <div id="recaptcha-container" />
            </ThemeProvider>
          </PaymentContextProvider>
        </ReduxProvider>
      </>
    )
  }
}

export default withRedux(createStore)(withRouter(InfinitePaymentLinkApp))
