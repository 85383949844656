import { createContext, useContext, useState, useEffect } from 'react'
import currency from 'currency-formatter'

const PaymentContext = createContext()

const parseAmont = (amount, hard_limit_amount) => {
  try {
    amount =
      amount &&
      amount
        .replace(/\./gi, ',')
        .split(',')
        .reduce((prev, current, index, array) => {
          return prev + (index === array.length - 1 ? ',' : '') + current
        })
    const cleanAmount = /\d+(,\d\d)?$/gi.test(amount)
    const parsedAmount = currency.unformat(amount, { code: 'BRL', precision: 2 })
    const validAmount = cleanAmount && !isNaN(parseFloat(amount)) && parsedAmount >= 1 && parsedAmount <= (hard_limit_amount - 1)
    const amountValue = parsedAmount.toFixed(2).replace('.', ',')
    const formattedValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parsedAmount)
    return {
      invalid_amount: !validAmount && cleanAmount,
      amount: validAmount && {
        value: amountValue,
        formattedValue: formattedValue,
        valueAsNumber: parsedAmount,
      },
    }
  } catch (err) {
  }
}




export function PaymentContextProvider({ children, values }) {
  const [amount, setAmount] = useState(0)
  const [installments, setInstallments] = useState(null)
  const [maxInstallments, setMaxInstallments] = useState(1)
  const { params, invoiceData } = values;

  // Fetch data
  useEffect( () => {
    if (params.amount && values?.userData?.hard_limit_amount) {
      const currentAmount = invoiceData?.info?.amount ? invoiceData?.info?.amount : parseFloat(params.amount.replace('.', '').replace(',', '.'))
      setAmount(currentAmount)
    }
  }, [params?.amount, values?.userData?.hard_limit_amount, invoiceData?.info?.amount]);
  useEffect( () => {
    if (values?.userData?.public_configuration?.max_installments) {
      setMaxInstallments(invoiceData?.info?.max_installments || values.userData.public_configuration.max_installments)
    }

  }, [values?.userData?.public_configuration?.max_installments, invoiceData?.info?.max_installments])
  const userData = values?.userData || {}
  const {
    value_with_fees: {
      capture_methods: {
        credit_card: valueWithFees
      } = {}
    } = {}, 
    info: {
      transfer_fees: transferFees
    } = {}
  } = invoiceData || {}
  return (
    <PaymentContext.Provider value={{ ...values, amount, userData, maxInstallments, setAmount, valueWithFees, transferFees, installments, setInstallments }}>
      {children}
    </PaymentContext.Provider>
  );
}

export function usePaymentContext() {
  return useContext(PaymentContext)
}

export default PaymentContext
