import { createWalletPayload } from 'components/ui/form/PaymentForm/schema';

import { useCallback, useEffect, useState } from "react";
import { createWalletPayment, setError, setSubmiting } from 'reducers/payments';
import { useDispatch } from 'react-redux';
import { usePaymentContext } from 'context/PaymentContext';
import useRudderStack from 'utils/useRudderStack';
import { useShallow } from 'zustand/react/shallow'

import { create } from 'zustand'
import { produce } from 'immer'
import  { useWalletPayStore, validateCaptcha } from './useWalletPay';
import { isProduction } from 'utils/is-production';
import { installmentPopupStore } from 'components/Wallet/WalletContainer';
import { toggleLoading } from 'reducers/redirectReceipt';
let resolvePromise;
const externalPromise = new Promise((resolve) => {
  resolvePromise = resolve;
})
export const GOOGLE_PAY_LOAD = () => {
  resolvePromise()
}

export const isProductionGoogle = () => {
  return isProduction() ? 'PRODUCTION' : 'TEST'
}

const useGooglePayStore = create((set) => ({
  canMakePayment: true,
  loading: true,
  setLoading: (loading) => set(produce((state) => { state.loading = loading})),
  setCanMakePayment: (response) => set(produce((state) => { state.canMakePayment = response})),
}))

const useGooglePay = () => {
  const { product_allowed } = usePaymentContext();
  const installment = useWalletPayStore(useShallow((state) => (state.installment)))
  const { setShowInstallmentPopup } = installmentPopupStore(useShallow((state) => ({ setShowInstallmentPopup: state.setShowInstallmentPopup } )))
  const {setLoading, setCanMakePayment} = useGooglePayStore(useShallow((state) => (
    {
      setLoading: state.setLoading,
      setCanMakePayment: state.setCanMakePayment,  
    }
  )))


  useEffect(() => {
    if (product_allowed && !product_allowed['google-pay']) {
      setLoading(false)
      setCanMakePayment(false)
    }
  }, [product_allowed, setCanMakePayment, setLoading])
  

  useEffect(() => {
    let isMounted = true;
    externalPromise.then(() => {
      if (isMounted) {
        const baseRequest = {
          "apiVersion": 2,
          "apiVersionMinor": 0,
          "allowedPaymentMethods": [
            {
              "type": "CARD",
              "parameters": {
                "allowedAuthMethods": [ "CRYPTOGRAM_3DS"],
                "allowedCardNetworks": ["MASTERCARD", "VISA"]
              }
            }
          ]
        }
        
        const paymentsClient = new google.payments.api.PaymentsClient({environment: isProductionGoogle()});
        paymentsClient.isReadyToPay(baseRequest).then((response) => {
          setCanMakePayment(response.result)
          setLoading(false)
        })
        .catch(function(err) {
          setLoading(false)
          setCanMakePayment(false)
        });
      }
    });
    return () => {
      isMounted = false; 
    };
  }, [externalPromise, setCanMakePayment]); 

  const dispatch = useDispatch();
  const { rudderStack, ready: readyRudderStack } = useRudderStack();
  const { params, amount } = usePaymentContext();  


  const sendPayment = useCallback(async (paymentData) => {
    setShowInstallmentPopup(false)
    dispatch(toggleLoading(true))
    const captchaToken = await validateCaptcha();
    if (!captchaToken) {
      return {
        transactionState: "ERROR",
        error: {
          reason: "OTHER_ERROR",
          message: "Erro ao validar o CAPTCHA",
          intent: "PAYMENT_AUTHORIZATION"
        }
      };
    }
    const phoneNumber = paymentData?.paymentMethodData?.info?.billingAddress?.phoneNumber;
    if (!phoneNumber?.replace(/\D/g, '').match(/^(55)?[0]?\d{2}9\d{4}\d{4}$/) || true) {
      dispatch(setError('invalid_phone_number'))
      readyRudderStack &&
        rudderStack?.track('Payment Link | GooglePay | Error Cellphone Returned')
      dispatch(toggleLoading(false))
      return 
    }
    const payment = {
      wallet_token: {
        token_type: 'google_pay',
        token_data: JSON.parse(paymentData?.paymentMethodData?.tokenizationData?.token)
      },
      phone_number: paymentData?.paymentMethodData?.info?.billingAddress?.phoneNumber,
      email: paymentData?.email,
    }
    const payload = {
      handle: params.handle,
      amount: amount.toFixed(2).replace('.', ','),
      payload: createWalletPayload({installments: installment}, payment, captchaToken),
      
    }
    
    const { meta  } = await dispatch(
      createWalletPayment(payload),
    )
    
    if (meta.condition === false) {
      readyRudderStack && rudderStack?.track('Payment Link | GooglePay | Error Payment Returned')
      dispatch(toggleLoading(false))
      return { transactionState: "ERROR" };
    }  else {
      readyRudderStack && rudderStack?.track('Payment Link | GooglePay | Success Payment')
      return {transactionState: "SUCCESS"};
    }

  }, [amount, params, readyRudderStack, installment])
  
  return {
    sendPayment
  }
}

export default useGooglePay;
export { useGooglePayStore };