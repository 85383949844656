import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import linkApi from 'libs/infinitepay/link'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const sendReceipt = createAsyncThunk(
  'receipt/sendReceipt',
  async (payload, { dispatch, rejectWithValue }) => {
    const response = await linkApi.sendReceipt(payload)
    return response
  },
)

const receiptSlice = createSlice({
  name: 'receipt',
  initialState: {
    fetching: false,
    error: null,
    active: false,
    data: null,
  },
  reducers: {
    showReceipt(state, action) {
      state.active = true
      if (action.payload.date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)){
        action.payload.date = format(new Date(action.payload.date), 'dd MMM yyyy - HH:mm', { locale: ptBR })
      }
      state.data = action.payload
    },
    hideReceipt(state) {
      state.active = false
    },
  },
})

const { actions, reducer } = receiptSlice

export const selectReceipt = (state) => state.receipt

export const { showReceipt, hideReceipt } = actions

export default reducer
