import { forwardRef } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Slide,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme, withStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import Image from 'next/image'
import ELEMENT_TEST_ID from '/playwright/support/elements'

const PopupTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default withStyles((theme) => ({
  paper: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      minWidth: 420,
    },
  },
  paperFullScreen: {
    borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    alignSelf: 'flex-end',
    height: 'fit-content',
    minHeight: 350,
  },
  closeButton: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
    padding: '0.625rem',
    '& svg': {
      fontSize: '1rem',
    },
  },
  dialogActions: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
  },
  dialogContent: {
    textAlign: 'center',
    margin: '0 auto',
    fontSize: '1rem',
    width: '100%',
    color: theme.palette.grey[900],
    lineHeight: 1.25,
    '&': {
      padding: `${theme.spacing(1)}px`,
    },
    '& button': {
      display: 'block',
      width: '80%',
      maxWidth: 280,
      margin: `${theme.spacing(4)}px auto ${theme.spacing(1)}px auto`,
    },
  },
  dialogTitle: {
    textAlign: 'center',
    margin: '0 auto',
    paddingBottom: 0,
    '& picture': {
      display: 'block',
      marginBottom: '1em',
      lineHeight: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 56,
      height: 56,
      position: 'relative',
      '& > *': {
        width: 'auto',
        height: '100%',
      },
    },
    '& h2': {
      fontSize: '1.5rem',
      lineHeight: 1.25,
      color: theme.palette.grey[800],
    },
  },
}))(
  ({
    open = false,
    title,
    icon,
    button,
    code,
    classes,
    children: Children,
    onClose,
    onHelperClick,
    fullScreen,
    maxWidth,
    ...props
  }) => {
    const theme = useTheme()
    const isMediumViewport = useMediaQuery(theme.breakpoints.up('md'))

    fullScreen ||= !isMediumViewport
    maxWidth ||= isMediumViewport && 'xs'

    return (
      <Dialog
        {...props}
        classes={{
          paperFullScreen: classes.paperFullScreen,
          paper: classes.paper,
        }}
        TransitionComponent={PopupTransition}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions className={classes.dialogActions}>
          <IconButton
            className={classes.closeButton}
            aria-label="Fechar"
            onClick={onClose}
            data-testid={ELEMENT_TEST_ID.closeButton}
          >
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogActions>
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} data-testid={ELEMENT_TEST_ID.tokenTitleText}>
          {icon && (
            <picture>
              {typeof icon === 'string' ? <Image src={icon} layout="fill" alt={title} /> : icon}
            </picture>
          )}
          {title}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {typeof Children === 'string' && (
            <DialogContentText id="alert-dialog-description">{Children}</DialogContentText>
          )}
          <DialogContent>
            {typeof Children === 'function' ? (
              <Children onHelperClick={onHelperClick} />
            ) : (
              typeof Children !== 'string' && Children
            )}
            {typeof button === 'string' ? (
              <Button variant="contained" color="primary" onClick={onClose} data-testid={ELEMENT_TEST_ID.closeMessageButton}>
                {button}
              </Button>
            ) : (
              button && { button }
            )}
          </DialogContent>
        </DialogContent>
      </Dialog>
    )
  },
)
