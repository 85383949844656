import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  useMediaQuery,
  Box,
} from '@material-ui/core'
import { useTheme, withStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import ELEMENT_TEST_ID from '/playwright/support/elements'
import { ApplePayButton } from 'components/ui/form'
import useInstallments from 'utils/useInstallments'
import { usePaymentContext } from 'context/PaymentContext'
import useApplePay from 'libs/useApplePay'
import { selectPayments } from 'reducers/payments'
import { useDispatch, useSelector } from 'react-redux'
import { useShallow } from 'zustand/react/shallow'
import { useWalletPayStore } from 'libs/useWalletPay'

import { GooglePayConfiguredButton } from './GooglePayConfiguredButton'
import { ApplePayConfiguredButton } from './ApplePayConfiguredButton'

const PopupTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const InstallmenLabel = withStyles((theme) => ({
  root: {
    textAlign: 'right'
  },
  box: {
    margin: '16px 0',
    padding: '16px',
    border: '2px solid rgba(224, 224, 224, 1)',
    borderRadius: '8px',
    gap: '8px',
    cursor: 'pointer',
    '&.selected': {
      borderColor: 'rgba(50, 50, 50, 1)',
    }
  },
  radioButton: {
    display: 'inline-block',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: '2px solid rgba(117, 117, 117, 1)',
    position: 'relative',
    '&:after': {
      opacity: 0,
      display: 'block',
      content: '""',
      position: 'absolute',
      top: '3px',
      borderRadius: '8px',
      bottom: '3px',
      left: '3px',
      right: '3px',
      backgroundColor: 'rgba(50, 50, 50, 1)',
    },
    '.selected &': {
      borderColor: 'rgba(50, 50, 50, 1)',
    }
    ,
    '.selected &:after': {
      opacity: 1,
    }
  },
  installment: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 700,
    '& span': {
      fontSize: '16px',
      fontWeight: 400,
    }
  },
}))(
  ({
    classes,
    installment,
    percentage,
    selected,
    amount,
    onClick,
    transferFees,
    totalValue,
    ...props
  }) => {
    const buttonRef = useRef(null);
    useEffect(() => {
      if (selected) {
        buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, [selected])
    const formattedTotalValue = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValue)
    const totalLabel = !transferFees ? '' : (installment > 1 ? `no total de ${formattedTotalValue}` :'' )
    return (
      <Box display={'flex'} className={`${classes.box} ${selected ? 'selected': ''}`} onClick={onClick} ref={buttonRef}>
        <Box className={classes.radioButton}></Box>
        <Box className={classes.installment} flex={1} >{installment}x <span>de {amount} {totalLabel}</span> </Box>
      </Box>
    )
})


export const InstallmentsModal = withStyles((theme) => ({
  paper: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      minWidth: 420,
    },
  },
  paperFullScreen: {
    borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    alignSelf: 'flex-end',
    height: 'fit-content',
  },
  closeButton: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
    padding: '0.625rem',
    '& svg': {
      fontSize: '1rem',
    },
  },
  dialogTitle: {
    flex: 1,
    fontSize: '19px',
    fontWeight: 500,
    textAlign: 'left'
  },
  dialogActions: {
    padding: '8px 16px'
  },
  dialogContent: {
    maxHeight: 'calc(100vh - 300px)',
    width: '100%',
    padding: '8px 0 0 0',
    color: theme.palette.grey[900],
    display: 'flex',
    flexDirection: 'column',
  },
  installmentsContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: '0 16px',
  },
  buttonContainer: {
    padding: '24px',
  }
}))(
  ({
    open = true,
    classes,
    children: Children,
    onClose,
    ...props
  }) => {
    
    const {setInstallment, installment, walletType} = useWalletPayStore(useShallow((state) => (
      {
        walletType: state.walletType,
        installment: state.installment,
        setInstallment: state.setInstallment
      }
    )));
    const { amount, transferFees, valueWithFees, setAmount } = usePaymentContext()
    const payments = useSelector(selectPayments)
    
    const { installmentsData } = useInstallments({baseAmount: amount})
    const theme = useTheme()
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'))
    const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'))
    const isMediumViewport = useMediaQuery(theme.breakpoints.up('md'))
  

    
    const installmentOnClose = useCallback(() => {
      onClose()
    }, [onClose])

    const setInstallmentAndAmount = useCallback((selectedInstallment) => {
      console.log('selectedInstallment', selectedInstallment)
      setInstallment(selectedInstallment)
      if (transferFees && selectedInstallment > 0) {
        setAmount(valueWithFees[selectedInstallment - 1].total_value)
      }
    }, [setInstallment, valueWithFees, transferFees, setAmount])

    return (
      <Dialog
        {...props}
        classes={{
          paperFullScreen: classes.paperFullScreen,
          paper: classes.paper,
        }}
        TransitionComponent={PopupTransition}
        fullScreen={matchesDownSm || !isMediumViewport}
        maxWidth={(matchesMd && 'sm') || (isMediumViewport && 'xs')}
        open={open}
        onClose={installmentOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions className={classes.dialogActions}>
          <Box className={classes.dialogTitle}>
            Escolha o número de parcelas
          </Box>
          <IconButton
            className={classes.closeButton}
            aria-label="Fechar"
            onClick={installmentOnClose}
            data-testid={ELEMENT_TEST_ID.closeButton}
          >
            <CloseIcon color="inherit" />
          </IconButton>
        </DialogActions>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.installmentsContainer} >
            {installmentsData.map((value, index) => (
              value.value !== 'default' && <InstallmenLabel key={`installment-${index}`}  transferFees={transferFees} totalValue={valueWithFees && valueWithFees[value.value-1]?.total_value} installment={value.value} selected={value.value == installment} amount={value.amount} onClick={() => setInstallmentAndAmount(value.value)}/>
            ))}
          </Box>
          {
            installment && walletType == 'apple_pay' && <Box className={classes.buttonContainer}>
              <ApplePayConfiguredButton />
            </Box>
          }
          {
            installment && walletType == 'google_pay' && <Box className={classes.buttonContainer}>
              <GooglePayConfiguredButton />
            </Box>
          }
        </DialogContent>
      </Dialog>
    )
  },
)

