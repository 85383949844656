import { createWalletPayload } from 'components/ui/form/PaymentForm/schema'
import linkApi from 'libs/infinitepay/link'
import config from 'config'
import { useCallback, useEffect } from 'react'
import { createWalletPayment, setSubmiting } from 'reducers/payments'
import { useDispatch } from 'react-redux'
import { usePaymentContext } from 'context/PaymentContext'
import useRudderStack from 'utils/useRudderStack'
import { useShallow } from 'zustand/react/shallow'

import { create } from 'zustand'
import { produce } from 'immer'
import { installmentPopupStore } from 'components/Wallet/WalletContainer';
import { useWalletPayStore, validateCaptcha } from './useWalletPay';

const useApplePayStore = create((set) => ({
  canMakePayment: true,
  loading: true,
  setLoading: (loading) => set(produce((state) => { state.loading = loading})),
  setCanMakePayment: (response) => set(produce((state) => { state.canMakePayment = response})),
}))

let LOADING = false
const MERCHANT_ID = config.appleMerchantId
const useApplePay = () => {
  const {canMakePayment, setLoading, setCanMakePayment} = useApplePayStore(useShallow((state) => (
    {
      canMakePayment: state.canMakePayment,
      setLoading: state.setLoading,
      setCanMakePayment: state.setCanMakePayment
    }
  )))
  const installment = useWalletPayStore((state) => (state.installment))
  const setShowInstallmentPopup = installmentPopupStore(useShallow((state) => (state.setShowInstallmentPopup)))


  const dispatch = useDispatch()
  const { rudderStack, ready: readyRudderStack } = useRudderStack()
  const { params } = usePaymentContext()

  useEffect(() => {
    const getCanMakePayment = async () => {
      if (typeof window.ApplePaySession !== 'undefined') {
        LOADING = true
        const response = await ApplePaySession.canMakePayments();
        setLoading(false);
        setCanMakePayment(response);
      } else {
        setLoading(false)
        setCanMakePayment(false);
      }
    }
    !LOADING && getCanMakePayment();
  }, [window.ApplePaySession])

  const getSession = async (validationURL) => {
    return await linkApi.applePaySession(validationURL)
  }

  const sendPayment = useCallback(async (event, amount) => {
    
    const captchaToken = await validateCaptcha();
    if (!captchaToken) {
      return false;
    }
    const payment = {
      wallet_token: {
        token_type: 'apple_pay',
        token_data: event.payment?.token?.paymentData
      },
      phone_number: event.payment?.shippingContact?.phoneNumber,
      email: null
    }
    const payload = {
      handle: params.handle,
      amount: amount.toFixed(2).replace('.', ','),
      payload: createWalletPayload({installments: installment}, payment, captchaToken),
      
    }
    setShowInstallmentPopup(false)
    const { meta  }  = await dispatch(
      createWalletPayment(payload),
    )
    if (meta.condition === false) {
      readyRudderStack && rudderStack?.track('Payment Link | ApplePay | Error Payment Returned')
      return false;
    }  else {
      readyRudderStack && rudderStack?.track('Payment Link | ApplePay | Success Payment')
      return true;
    }
  }, [installment]); 
  
  const handleApplePay = useCallback( async (amount) => {
    readyRudderStack && rudderStack?.track('Payment Link | ApplePay | Button Clicked')

    dispatch(setSubmiting(true))
    
    if (canMakePayment) {
      const request = {
        countryCode: 'BR',
        currencyCode: 'BRL',
        requiredShippingContactFields: ['phone'],
        supportedNetworks: ['masterCard', 'visa'],
        merchantCapabilities: ['supports3DS', 'supportsCredit'],
        total: { label: params.handle, amount: amount },
      }
      const session = new ApplePaySession(3, request)

      session.onvalidatemerchant = async (event) => {
        session.completeMerchantValidation(await getSession(event.validationURL))
      }

      session.onpaymentauthorized = async (event) => {
        const phoneNumber = event.payment?.shippingContact?.phoneNumber
        if (!phoneNumber?.replace(/\D/g, '').match(/^(55)?[0]?\d{2}9\d{4}\d{4}$/)) {
          const err = new ApplePayError(
            'shippingContactInvalid',
            'phoneNumber',
            'Número de telefone celular inválido, (XX) 9XXXX-XXXX',
          )
          session.completePayment({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [err],
          })
          readyRudderStack &&
            rudderStack?.track('Payment Link | ApplePay | Error Cellphone Returned')
        } else {
          const response = await sendPayment(event, amount)
          if (response) {
            session.completePayment(ApplePaySession.STATUS_SUCCESS)
          } else {
            session.completePayment(ApplePaySession.STATUS_FAILURE)
          }
        }
      } 
      dispatch(setSubmiting(false))
      
      session.oncancel = () => {
        readyRudderStack && rudderStack?.track('Payment Link | ApplePay | Payment Closed')
        dispatch(setSubmiting(false))
      }
      session.begin();
    }
  }, [canMakePayment, params, readyRudderStack, sendPayment])

  return {
    handleApplePay,
  }
}

export default useApplePay
export { useApplePayStore }
