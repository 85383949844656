import { selectPayments } from "reducers/payments";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import Popup from "components/ui/Popup";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from '@material-ui/styles'
import { warningFindByCode } from "components/ui/form/PaymentForm/messages";


export function ErrorModal({}) {
  const payments = useSelector(selectPayments)
  
  const [errorPopup, setErrorPopup] = useState({
    open: false,
  })
  const { info: infoPopup, open: openPopup } = errorPopup
  const onPopupErrorClose = useCallback(() => {
    setErrorPopup((current) => {
      return {
        ...current,
        open: false,
      }
    })
  }, [setErrorPopup])
  const theme = useTheme()
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'))
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  
  useEffect(() => {
    if (payments.error) {
      const info = warningFindByCode(payments.error)
      setTimeout(() => {
        setErrorPopup(() => {
          return {
            open: true,
            info: info,
          }
        })
      }, 100)
    }
  }, [setErrorPopup, payments.error])

  return <Popup
        fullScreen={matchesDownSm}
        maxWidth={matchesMd && 'sm'}
        open={errorPopup.open}
        title={infoPopup?.title}
        icon={infoPopup?.icon}
        button={infoPopup?.button}
        onClose={onPopupErrorClose}
      >
        {infoPopup?.content ?? ''}
      </Popup>;
}
