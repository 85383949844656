/* eslint-disable */
import m from '../../components/ui/form/PaymentForm/messages'

const messages = {
  "the property '#/billing_details/email' of type null did not match the following type: string in schema https://www.infinitepay.io/docs#":
    'Seu e-mail não está configurado corretamente, se autentique novamente para configurá-lo e finalizar o cadastro',
  'cannot be referred by the same user making the purchase!':
    'O link de indicação não pode ser utilizado pelo mesmo usuário que está comprando',
  'invalid qsa':
    'O nome do representante legal não foi encontrado no quadro societário da empresa. Por favor, verifique os dados do representante legal inseridos e corrija o cadastro utilizando os dados de um dos sócios da empresa.',
  'coupon not found': 'A indicação informada não existe, verifique novamente',
  'has already been taken': '#value# já foi utilizado',
  missing: 'Paramêtros inválidos',
  'cnpj is not permitted':
    'Não foi possível prosseguir com o seu cadastro. As informações do seu negócio foram analisadas e identificamos algumas incompatibilidades com as nossas políticas internas.',
  'cnpj is inactive': 'CNPJ não ativo, verifique a situação de sua empresa.',
  'document number is invalid': 'CNPJ inválido',
  'parameter is required': 'Campo obrigatório',
  "can't be blank": 'Campo obrigatório',
  'failed to login': 'Requisição não autorizada',
  'not authorized': 'Requisição não autorizada',
  'not found': 'Erro inesperado no servidor, por favor tente novamente em alguns minutos',
  'middleware timeout':
    'Erro de resposta lenta no servidor, por favor tente novamente em alguns minutos',
  'request timeout':
    'Erro de resposta lenta no servidor, por favor tente novamente em alguns minutos',
  'service timeout':
    'Erro de resposta lenta no servidor, por favor tente novamente em alguns minutos',
  'unprocessable entity':
    'Erro de resposta lenta no servidor, por favor tente novamente em alguns minutos',
  'internal server error':
    'Erro inesperado no servidor, por favor tente novamente em alguns minutos',
  'retrieving cnpj data in background. try again in a few seconds.':
    'Não foi possível confirmar o CNPJ. Por favor, tente novamente em alguns minutos.',
  'retrieving cnpj data in background. try again in a few seconds':
    'Não foi possível confirmar o CNPJ. Por favor, tente novamente em alguns minutos.',
  'invalid card number': 'Número de cartão inválido',
  'email already registered': 'Este e-mail já foi registrado',
  'legal representative not found on qsa':
    'O nome do representante legal não foi encontrado no quadro societário da empresa. Por favor, verifique os dados do representante legal inseridos e corrija o cadastro utilizando os dados de um dos sócios da empresa.',
  "property 'card_expiration_date' should be in format 'MMYY'":
    'A data de expiração do cartão deve estar no formato MMAA',
  'legal representative age should be equal to or greater than 18 years':
    'A idade do Representante Legal deve ser maior ou igual à 18 anos.',
  'cnpj is not active': 'CNPJ não ativo, verifique a situação de sua empresa.',
  'cnpj is not registered': 'CNPJ não registrado, verifique a situação de sua empresa.',
  'cnpj is unfit': 'CNPJ inapto, verifique a situação de sua empresa.',
  'phone number already registered': 'Este número de telefone já foi registrado.',
  "property 'merchant_name' should have between 3 and 100 characters":
    'O campo Nome de Exibicão deve ter de 3 a 100 caracteres',
  "property 'name' should have between 3 and 100 characters":
    'O campo Nome deve ter de 3 a 100 caracteres',
  "property 'phone_number' is not a valid mobile number": 'Número de telefone celular inválido',
  "property 'email' is not a valid address": 'Endereço de e-mail inválido',
  "property 'business_category' is not valid": 'Categoria de negócio inválida',
  'document number already registered': 'Este número de documento já foi registrado (CNPJ)',
  "property 'bank.branch_number' should not contain only zeros":
    'Número da agência não pode conter apenas zeros',
  "property 'bank.document_number' is missing":
    'Número de CPF/CNPJ do titular da conta bancária está vazio',
  "property 'bank.document_number' should be equal 'legal_representative.cpf'":
    'Informações do titular da conta bancária devem ser as mesmas do representante legal',
  "property 'bank.document_number' is not a valid cpf or cnpj":
    'Número de CPF/CNPJ do titular da conta bancária inválido',
  "property 'bank.document_number' should be a valid cnpj":
    'Número de CNPJ do titular da conta bancária inválido',
  "property 'bank.legal_name' should have between 3 and 100 characters":
    'O nome da empresa deve ter entre 3 a 250 caracteres',
  "property 'bank.legal_name' should have between 3 and 250 characters":
    'O nome da empresa deve ter entre 3 a 250 caracteres',
  "property 'bank.bank_code' should be the 3 digit code for your bank":
    'Número do código bancário deve conter 3 dígitos',
  "property 'bank.branch_number' should be a number with 4 digits":
    'Número da agência bancária deve conter 4 dígitos',
  "property 'bank.branch_dv' should have 1 characters":
    'Dígito da agência bancária deve possuir 1 caracter apenas',
  "property 'bank.account_number' should be a number with at most 13 digits":
    'Número da conta bancária deve ter entre 3 até 13 dígitos',
  "property 'bank.account_dv' should have 1 or 2 characters":
    'Dígito da conta bancária deve possui de 1 ou 2 caracteres',
  "property 'income' should be a valid float number":
    'Informações de renda devem ser um número válido',
  "property 'wealth' should be a valid float number": 'Patrimônio deve ser um número válido',
  "property 'income' is bigger than the limit": 'Renda está maior do que limite',
  "property 'wealth' is bigger than the limit": 'Patrimônio está maior do que limite',
  "property 'address.zip_code' should be a valid zip_code in the format xxxxx-xxx":
    'CEP deve ser um número válido, no formato 00000-000',
  "property 'address.complement' should have less than 20 characters":
    'Complemento do endereço deve ter menos de 20 caracteres',
  "property 'legal_representative.cpf' is not a valid cpf": 'CPF do representante legal inválido',
  "property 'legal_representative.full_name' should be a string with at least 5 characters":
    'Nome completo do representante legal deve conter no mínimo 5 caracteres',
  "property 'legal_representative.job' should be a string with at least 3 characters":
    'Ocupação profissional do representante legal deve conter no mínimo 3 caracteres',
  "property 'legal_representative.phone_number' is not a valid phone number":
    'Telefone do representante legal inválido',
  'Payed user is not a merchant': 'O usuário não está habilitado para receber pagamento',
  "property 'legal_representative.birthday' should be a valid date in the format dd/mm/yyyy":
    'Data de nascimento do representante legal deve ser uma data válida, no formato DD/MM/AAAA',
  'too many denied transactions. blocked by 24 hours':
    'Muitas transações negadas. Bloqueio por 24 horas',
  'amount less than 1,00 not allowed!': 'Valor inferior a 1,00 não permitido!',
  'installment amount less than 1,00 its not allowed':
    'Valor da parcela menor que 1,00 não é permitido',
  'Network Error':
    'Erro de conexão. Por favor, tente novamente por outro tipo de conexão (Wi-Fi/4G)',
}

const getMessage = (value = '') => {
  const message =
    messages[((value && value.toString()) || '').toLowerCase()] || 'Erro inesperado no servidor, por favor tente novamente em alguns minutos'
  return message
}

export const processResponseError = (response = {}) => {
  const data = response?.errors ? response : response?.data || {}
  if (data.errors) {
    let message = false
    if (Array.isArray(data.errors)) {
      data.errors = data.errors[0]
      if (data.errors.title) {
        data.errors = { field: data.errors.title }
      }
    }
    Object.keys(data.errors).forEach((item) => {
      let source, needTranslate = true
      if (Array.isArray(data.errors[item])) {
        switch (item) {
          case 'email':
            needTranslate = false
            source =  data.errors[item][0] == "Could't be changed" || data.errors[item][0] == "has already been taken" ? m.email.already_taken : m.email.invalid
            break
          default:
            
            source = m[item]?.invalid || data.errors[item][0]
            if (!source) {
              needTranslate = false
              source = messages['not found']
            }
            break
        }
      } else {
        source = data.errors[item]
      }
      message = needTranslate ? getMessage(source) : source
    })
    return message
  } else if (data.error || data.message) {
    const message = (data.error && data.error.message) || data.error || data.message || ''
    return getMessage(Array.isArray(message) ? message[0] : message)
  } else if (response.statusText || typeof data === 'string') {
    return getMessage(response.statusText || data)
  }
  return void 0
}
