import linkApi from 'libs/infinitepay/link'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { paymentLinkApiEndpoint } from 'config'
import { processResponseError } from 'libs/infinitepay/messages'
import { redirectToReceipt } from './redirectReceipt'
import { hideForm } from './payments'
import config from 'config'
import { captureException, captureMessage } from '@sentry/nextjs'

export const AUTHORIZATION_CODE = {
  ACCEPTED: '00',
  THREEDS: 'DS',
  UNICOPAY: 'UP',
}

export const sendEnrollment = createAsyncThunk(
  'threeds/sendEnrollment',
  async (dsParams, { rejectWithValue, getState, dispatch }) => {
    const { transaction } = getState().threeds

    try {
      const params = {
        device_info: {
          http_accept_browser_value: dsParams.accept,
          http_accept_content: dsParams.accept,
          http_browser_language: navigator.language,
          http_browser_java_enabled: navigator.javaEnabled(),
          http_browser_java_script_enabled: true,
          http_browser_color_depth: window.screen.colorDepth,
          http_browser_screen_height: window.innerHeight,
          http_browser_screen_width: window.innerWidth,
          http_browser_time_difference: new Date().getTimezoneOffset(),
          user_agent_browser_value: navigator.userAgent,
          device_channel: 'browser',
        },
        callback_url: `${paymentLinkApiEndpoint}/callback/?nsu=${transaction.nsu}`,
      }

      dsParams?.rudderStack?.track('Payment Link | 3DS | Enrollment Started', {
        amount: transaction?.receipt?.amount,
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        merchant_id: transaction?.handle,
      })

      const response = await linkApi.enrollment(transaction.handle, transaction.nsu, params)
      const {
        data: { attributes },
      } = response

      let ret

      if (attributes?.authorization_code === AUTHORIZATION_CODE.ACCEPTED) {
        ret = { status: 'accept' }
      } else if (
        attributes?.authorization_code.toUpperCase() == AUTHORIZATION_CODE.THREEDS &&
        attributes?.three_ds
      ) {
        const { challenge_url, access_token, width, height } = attributes.three_ds
        let url = challenge_url
        ret = {
          status: 'challenge',
          challenge: { source: url, width: width, height: height, jwt: access_token },
        }
      } else {
        linkApi.setNSU()
        ret = { status: 'denied', code: attributes?.authorization_code }
      }

      dsParams?.rudderStack?.track('Payment Link | 3DS | Enrollment Finished', {
        amount: transaction?.receipt?.amount,
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        merchant_id: transaction?.handle,
        ...ret,
      })

      if (ret.status == 'accept') {
        dispatch(hideForm())
        dispatch(redirectToReceipt(`${config.receiptsURL}/${transaction?.receipt?.transactionId}`))
      }
      return ret
    } catch (err) {
      dsParams?.rudderStack?.track('Payment Link | 3DS | Enrollment Error', {
        amount: transaction?.receipt?.amount,
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        merchant_id: transaction?.handle,
        error: err.response || err.message,
      })

      return rejectWithValue(processResponseError(err.response || err.message))
    }
  },
)
export const sendValidade = createAsyncThunk(
  'threeds/sendValidade',
  async (dsParams, { rejectWithValue, getState, dispatch }) => {
    try {
      const { transaction } = getState().threeds
      if (!dsParams.TransactionId) {
        dsParams?.rudderStack?.track('Payment Link | 3DS | Transaction ID NOT FOUND')

        captureMessage(`dsParams TransactionId not found ${JSON.stringify(dsParams)}`)
        captureException(new Error(`dsParams TransactionId not found`))
        return rejectWithValue(
          'Oops! Parece que ocorreu um erro inesperado. Para uma melhor experiência, recomendamos que você tente acessar por um navegador.',
        )
      }

      const params = {
        authentication_transaction_id: dsParams.TransactionId,
      }
      const response = await linkApi.validate(transaction.handle, transaction.nsu, params)

      const {
        data: { attributes },
      } = response

      let ret

      if (attributes?.authorization_code == AUTHORIZATION_CODE.ACCEPTED) {
        ret = { status: 'accept' }
      } else {
        linkApi.setNSU()
        ret = { status: 'denied', code: attributes?.authorization_code }
      }

      dsParams?.rudderStack?.track('Payment Link | 3DS | Challenge Finished', {
        amount: transaction?.receipt?.amount,
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        merchant_id: transaction?.handle,
        ...ret,
      })

      if (ret.status == 'accept') {
        dispatch(hideForm())
        dispatch(redirectToReceipt(`${config.receiptsURL}/${transaction?.nsu}`))
      }
      return ret
    } catch (err) {
      dsParams?.rudderStack?.track('Payment Link | 3DS | Challenge Error', {
        amount: transaction?.receipt?.amount,
        brand: transaction?.receipt?.cardType,
        nsu: transaction?.nsu,
        merchant_id: transaction?.handle,
        error: err.response || err.message,
      })

      return rejectWithValue(processResponseError(err.response || err.message))
    }
  },
)

const threeDSSlice = createSlice({
  name: 'threeds',
  initialState: {
    loading: false,
    error: null,
    transaction: null,
    nsu: null,
    form_post: null,
    challenge: null,
  },
  reducers: {
    showFormPost: (state, action) => {
      state.loading = true
      state.challenge = state.error = null
      state.transaction = action.payload.transaction
      if (action.payload.form) {
        state.form_post = action.payload.form
      } else if (action.payload.challenge) {
        state.challenge = action.payload.challenge
      }
    },
    hideThreeDS: (state, action) => {
      state.loading = false
      linkApi.setNSU()
      state.challenge = state.form_post = state.error = null
    },
  },
  extraReducers: {
    [sendEnrollment.pending]: (state, action) => {
      state.loading = true
      // reinitializing states to start first step
      // form_post is for first step
      // challenge is for second step
      state.challenge = state.error = null
    },
    [sendEnrollment.fulfilled]: (state, action) => {
      state.loading = action.payload.status === 'challenge'

      // after first step, we clean the form
      state.form_post = state.error = null
      if (action.payload.status === 'denied') {
        // case catched erro, setting message
        state.error = action.payload.code
      }
      // case status need second step, we set the challenge
      state.challenge = action.payload.status === 'challenge' ? action.payload.challenge : null
    },
    [sendEnrollment.rejected]: (state, action) => {
      state.loading = false
      // case uncatched erro, reinitializing states and set message
      state.error = action.payload
      state.challenge = state.form_post = null
    },
    [sendValidade.pending]: (state, action) => {
      state.loading = true
      // reinitializing states to start second step
      state.challenge = state.form_post = state.error = null
    },
    [sendValidade.fulfilled]: (state, action) => {
      state.loading = false
      // cleaning states after second step
      state.challenge = state.form_post = state.error = null
      if (action.payload.status === 'denied') {
        // case catched erro, setting message
        state.error = action.payload.code
      }
    },
    [sendValidade.rejected]: (state, action) => {
      state.loading = false
      // case uncatched erro, reinitializing states and set message
      state.error = action.payload
      state.challenge = state.form_post = null
    },
  },
})

const { actions, reducer } = threeDSSlice

export const selectThreeDS = (state) => state.threeds

export const { hideThreeDS, showFormPost } = actions

export default reducer
