import { usePaymentContext } from 'context/PaymentContext';
import { useWalletPayStore } from 'libs/useWalletPay';
import { useEffect, useState } from 'react';

const useInstallments = ({baseAmount}) => {
  const setInstallment = useWalletPayStore((state) => state.setInstallment);
  const { maxInstallments, transferFees, valueWithFees } = usePaymentContext()
  function getInstallmentsWithoutFee() {
    return [...new Array(maxInstallments)]
      .map((value, index) => {
        const installment = index + 1
        const installmentAmount = baseAmount / installment
        if (installmentAmount < 1) return
        let amount = installmentAmount.toFixed(5).replace('.', ',')
        amount = amount.substr(0, amount.length - 3)
        const formattedValue = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(installmentAmount)
        return {
          label: (
            <>
              <span>{installment}x sem juros</span>
              <strong>&nbsp;R${amount}</strong>
            </>
          ),
          option: `${installment}x sem juros R$ ${amount}`,
          value: installment,
          amount: formattedValue,
        }
      })
      .filter(Boolean)
  }
  function getInstallmentsWithFees() {
    const options =
      valueWithFees.map((installment) => {
        const formattedValue = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(installment.installment_value)
        return {
          label: (
            <>
              <span>
                {installment.installment}x {transferFees ? 'de' : 'sem juros'}
              </span>
              <strong>&nbsp;{formattedValue}</strong>
            </>
          ),
          option: `${installment.installment}x ${
            transferFees ? 'de' : 'sem juros'
          } ${formattedValue}`,
          value: installment.installment,
          amount: formattedValue,
        }
      }) ?? []
    if (transferFees) {
      options.unshift({
        label: (
          <>
            <span>Selecione o número de parcelas</span>
          </>
        ),
        option: 'Selecione o número de parcelas',
        value: 'default',
        disabled: true,
        className: 'option-default',
      })
    }
    return options
  }
  const [installmentsData] = useState(() => {
    return valueWithFees ? getInstallmentsWithFees() : getInstallmentsWithoutFee()
  })

  useEffect(() => {
    setInstallment(installmentsData[installmentsData.length - 1].value)
  }, [installmentsData])

  return { installmentsData }
}

export default useInstallments
