const ELEMENT_TEST_ID = {

  //Texts:
  notFoundTitleText: 'not-found-title-txt',
  notFoundDescriptionText: 'not-found-description-txt',
  titleText: 'title-txt',
  handleText: 'handle-txt',
  fantasyNameText: 'fantasy-name-txt',
  documentNumberText: 'document-number-txt',
  amountText: 'amount-txt',
  tokenTitleText: 'token-title-txt',
  tokenDescriptionText: 'token-description-txt',
  tokenText: 'token-txt',
  receiptTitleText: 'receipt-title-txt',
  receiptAmountText: 'receipt-amount-txt',
  receiptCardDataText: 'receipt-card-data-txt',
  receiptInstallmentsText: 'receipt-installments-txt',
  receiptDateText: 'receipt-date-txt',
  receiptFromText: 'receipt-from-txt',
  receiptToText: 'receipt-to-txt',

  //Buttons:
  payNowButton: 'pay-now-btn',
  payAppButton: 'pay-app-btn',
  payAmountButton: 'pay-amount-btn',
  submitButton: 'submit-btn',
  closeButton: 'close-btn',
  editPhoneNumberButton: 'edit-phone-number-btn',
  sendSmsButton: 'send-sms-btn',
  sendWhatsAppButton: 'send-whatsapp-btn',
  downloadShareButton: 'receipt-download-share-btn',
  closeMessageButton: 'close-message-btn',

  //Inputs:
  phoneNumberInput: 'phone-number-input',
  emailInput: 'email-input',
  installmentsInput: 'installments-input',
  installmenstSelectDesktop: '#mui-component-select-installments',
  cardNumberInput: 'card-number-input',
  expirationDateInput: 'expiration-date-input',
  cvvInput: 'cvv-input',
  cardholderNameInput: 'cardholder-name-input',
  documentNumberInput: 'document-number-input',
  zipcodeInput: 'zipcode-input',
  addressInput: 'address-input',
  cityInput: 'city-input',
  numberInput: 'number-input',
  stateInput: 'state-input',
  stateSelectDesktop: '#mui-component-select-billing_state',
  tokenInput: 'token-input',

  //Links/URLs:
  homeBannerUrl: 'home-banner-url',
  
  //Images:
  receiptCardBrandImage: 'receipt-card-brand-img'
}

export default ELEMENT_TEST_ID
