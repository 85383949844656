import { createSlice } from '@reduxjs/toolkit'


const redirectReceiptSlice = createSlice({
  name: 'redirect',
  initialState: {
    active: false,
  },
  reducers: {
    redirectToReceipt(state, action) {
      state.active = true
      setTimeout(() => {
        window.location.href = action.payload
      }, 100)
    },
    toggleLoading(state, action) {
      state.active = action.payload
    },
  },
})

const { actions, reducer } = redirectReceiptSlice

export const selectRedirectReceipt = (state) => state.redirect

export const { redirectToReceipt, toggleLoading } = actions

export default reducer
