import { ValidationError } from "yup"

// https://stackoverflow.com/questions/54733539/javascript-implementation-of-lodash-set-method
function set(obj, path, value) {
  if (Object(obj) !== obj) return obj
  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || []
  path
    .slice(0, -1)
    .reduce(
      (a, c, i) =>
        Object(a[c]) === a[c]
          ? a[c]
          : (a[c] = Math.abs(path[i + 1]) >> 0 === +path[i + 1] ? [] : {}),
      obj,
    )[path[path.length - 1]] = value
  return obj
}

function normalizeValidationError(err, translator) {
  return err.inner.reduce((errors, innerError) => {
    const { path, message } = innerError
    const el = translator ? translator(innerError) : message
    set(errors, path, [el])
    return errors
  }, {})
}

export function makeValidate(validator, translator, options) {
  return async (values) => {
    try {
      await validator.validate(values, { abortEarly: false })
      return {}
    } catch (err) {
      
      if (err?.inner &&  Array.isArray(err.inner)) {
        return normalizeValidationError(err, translator)
      }
      throw err;
    }
  }
}

export function makeValidateSync(validator, translator) {
  return (values) => {
    try {
      validator.validateSync(values, { abortEarly: false })
      return {}
    } catch (err) {
      
      if (err?.inner &&  Array.isArray(err.inner)) {
        return normalizeValidationError(err, translator)
      }
      throw err;
    }
  }
}
